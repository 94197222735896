/*
 * @Description: 请求的方法封装
 * @Author: zhang zhen
 * @Date: 2023-02-20 09:32:55
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-08-15 23:38:09
 * @FilePath: /page-sass/src/util/request.js
 */
import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import {
  Modal,
  message,
  notification
} from 'ant-design-vue'
import router from '@/router'
/**
 * 【指定 axios的 baseURL】
 * 如果手工指定 baseURL
 * 则映射后端域名，通过 vue.config.js
 * @type {*|string}
 */
const {
  NODE_ENV,
  VUE_APP_API_BASE_URL
} = process.env;
let apiBaseUrl = VUE_APP_API_BASE_URL;
// console.log("apiBaseUrl", apiBaseUrl)
// 创建 axios 实例
const service = axios.create({
  baseURL: apiBaseUrl, // api base_url
  // timeout: 9000 // 请求超时时间
})

const err = (error) => {
  console.warn('header', error.response)
  if (error.response) {
    let data = error.response.data

    // const token = Vue.ls.get(ACCESS_TOKEN)
    // console.log("------异常响应------", token)
    console.log("------异常响应------", error.response.status)
    if (data.type == 'InvalidRequestException') {
      return Promise.reject(error)
    }
    switch (error.response.status) {
      case 403:
        notification.error({
          message: '系统提示',
          description: '拒绝访问',
          duration: 4
        })
        break
      case 500:
        // update-begin- --- author:liusq ------ date:20200910 ---- for:处理Blob情况----
        let type = error.response.request.responseType;
        if (type === 'blob') {
          try {
            blobToJson(data);
          } catch (e) {
            console.log(data)
            if (data.type == 'application/json') {
              // 创建一个 FileReader 对象
              let reader = new FileReader();

              // 当 FileReader 读取完 Blob 的内容后，调用此函数
              reader.onload = function (event) {
                // 将读取到的内容（文本）解析为 JSON 对象
                let jsonObj = JSON.parse(event.target.result);
                console.log(jsonObj); // 输出 JSON 对象
                return message.error(jsonObj.message || '当前数据有问题')
                // return Promise.reject(jsonObj)
              };

              // 读取 Blob 的内容为文本
              reader.readAsText(data);
            } else {
              notification.error({ message: '系统提示', description: '未查询到可导出的数据', duration: 4 })
            }
          }
          break;
        }

        // U-0008
        if (error.response.data.code == 'U-0008') {
         const isShowLogin = localStorage.getItem("isShowLogin")
          console.log("aaaaaaaaaaaaaaaaa")
          console.log(isShowLogin)
          if (isShowLogin!='1') {
            localStorage.setItem('isShowLogin',"1")
            Modal.error({
              title: '登录已过期',
              content: '很抱歉，登录已过期，请重新登录',
              okText: '重新登录',
              mask: false,
              onOk: () => {
                localStorage.setItem('isShowLogin',"0")
                localStorage.removeItem("authInfo")
                router.push('/login')
              }
            })
          }
        } else {
          notification.error({
            message: '系统提示',
            description: error.response.data.message,
            duration: 2
          })
        }
        break
      case 404:
        notification.error({
          message: '系统提示',
          description: '很抱歉，资源未找到!',
          duration: 4
        })
        break
      case 504:
        notification.error({
          message: '系统提示',
          description: '网络超时'
        })
        break
      case 401:
        notification.error({
          message: '系统提示',
          description: '未授权，请重新登录',
          duration: 4
        })
        if (token) {
          //   store.dispatch('loginOut').then(() => {
          //     setTimeout(() => {
          //       window.location.reload()
          //     }, 1500)
          //   })
        }
        break
      default:
        notification.error({
          message: '系统提示',
          description: data.message,
          duration: 4
        })
        break
    }
  }
  return Promise.reject(error)
};

// request interceptors request
service.interceptors.request.use(config => {
  const authInfo = localStorage.getItem('authInfo')
  if (authInfo) {
    const {
      Authorization
    } = JSON.parse(authInfo)
    config.headers['token'] = Authorization // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

// response interceptors response
service.interceptors.response.use((response) => {
  let res = response.data;
  if (res.code == 'U-0008') {
    store.dispatch('loginOut')
    router.push('/login')
    return;
    // return notification.error({
    //   message: '系统提示',
    //   description: res.message
    // })
  }
  const contentDisposition = response.headers['content-disposition'];
  if (contentDisposition) {
    const fileName = decodeURIComponent(contentDisposition.substring(contentDisposition.indexOf('=') + 1)).split("''")[1];
    console.log(fileName, 'fileName')
    fileName && (window.TemplateName = fileName) // 存放文件名称
  }

  return res
}, err)

export {
  service as axios
}
