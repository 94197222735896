<!--
 * @Description: 工作台的头部
 * @Author: zhang zhen
 * @Date: 2023-05-12 17:31:40
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-08-12 23:23:18
 * @FilePath: /page-sass/src/components/page/workSpaceHeader.vue
-->
<template>
  <div class="workSpaceHeader">
    <div class="left-icon" @click="handleChangeMenus">
      <a-icon type="menu-fold" v-if="!collapsed" title="收起" />
      <a-icon type="menu-unfold" v-else title="展开" />
    </div>
    <div class="right-info">
      <!-- <router-link tag="div" to="/" class="nav-link">
        <img src="~@/assets/newHomePage/back.png" width="16px" />
      </router-link> -->
      <router-link tag="div" to="/" class="link-text backToHome">返回首页</router-link>
<!--      <div class="link-text sendInfo" @click="handleNavigatePage('product')"-->
<!--           v-if="business?.roleType === BusinessRoleType.PURCHASER">发布需求-->
<!--      </div>-->
      <div class="link-text ">
        <a-popover placement="bottomRight" :autoAdjustOverflow="true" :arrowPointAtCenter="false"
                   overlayClassName="header-notice-wrapper-wx"
                   :overlayStyle="{ width: '192px', top: '50px' }">
          <template slot="content">
            <div style="align-items: center;display: flex;justify-content: center;flex-direction: column">
              <img src="@/assets/xcx-wx.png">
              <div class="header-title-wx">微信小程序扫码操作工作台</div>
            </div>
          </template>
          <div class="link-text">小程序</div>
        </a-popover>
      </div>
      <div class="link-text link-select" @click="handleActModel">
        <img src="@/assets/nav-bar-activity.png" class="nav-bar-img" />
        <div class="link-text-tip">
          <div class="link-triangle"></div>
          <div class="link-rectangle">优惠活动</div>
        </div>
      </div>
      <div class="link-text link-select" @click="handleTrialModel">
        <img src="@/assets/nav-bar-trial.png" class="nav-bar-img">
        <div class="link-text-tip">
          <div class="link-triangle"></div>
          <div class="link-rectangle">免费试用</div>
        </div>
      </div>
      <router-link tag="div" to="/messageManagement/messageList" class="nav-link notice-areas">
        <a-popover placement="bottomRight" :autoAdjustOverflow="true" :arrowPointAtCenter="false"
                   overlayClassName="header-notice-wrapper"
                   :overlayStyle="{ width: '364px', top: '50px' }">
          <template slot="content">
            <div class="header-title">
              <img src="/notice_icon.png" alt="" class="alarm">
              <span>消息中心</span>
            </div>
            <div class="notice-content-item">
              <span class="left-label">
                <span class="label">系统消息</span>
                <a-badge :count="countInfo.msgCategory_1 || 0" :overflow-count="999"
                         :number-style="{ backgroundColor: '#EE4261' }" v-if="countInfo.msgCategory_1" />
                <a-badge v-else :showZero="true" count="0" :number-style="{
      backgroundColor: '#EFEFEF',
      color: 'rgba(0, 0, 0, .25)',
      boxShadow: '0 0 0 1px #d9d9d9 inset',
    }" />
              </span>
              <img src="/showInfo.png" alt="" class="showInfo" @click="handleJump(1)">
            </div>
            <div class="notice-content-item">
              <span class="left-label">
                <span class="label">服务消息</span>
                <a-badge :count="countInfo.msgCategory_2 || 0" :overflow-count="999"
                         :number-style="{ backgroundColor: '#EE4261' }" v-if="countInfo.msgCategory_2" />
                <a-badge v-else :showZero="true" count="0" :number-style="{
      backgroundColor: '#EFEFEF',
      color: 'rgba(0, 0, 0, .25)',
      boxShadow: '0 0 0 1px #d9d9d9 inset',
    }" />
              </span>
              <img src="/showInfo.png" alt="" class="showInfo" @click="handleJump(2)">
            </div>
            <div class="notice-content-item">
              <span class="left-label">
                <span class="label">客户消息</span>
                <a-badge :count="countInfo.msgCategory_3 || 0" :overflow-count="999"
                         :number-style="{ backgroundColor: '#EE4261' }" v-if="countInfo.msgCategory_3" />
                <a-badge v-else :showZero="true" count="0" :number-style="{
      backgroundColor: '#EFEFEF',
      color: 'rgba(0, 0, 0, .25)',
      boxShadow: '0 0 0 1px #d9d9d9 inset',
    }" />
              </span>
              <img src="/showInfo.png" alt="" class="showInfo" @click="handleJumpChat">
            </div>
          </template>
          <a-badge :count="messageCount" :number-style="{ backgroundColor: '#EE4261' }" :overflow-count="999">
            <div class="link-text">消息</div>
          </a-badge>
        </a-popover>
      </router-link>
      <Menus :showEntry="false" class="noLeft" />
    </div>
    <a-modal
      class="try-model-box"
      :footer="null"
      v-model="tryVisible"
      :closable="false"
      :width="336"
    >
      <div class="try-model">
        <img src="@/assets/nav-close.png" class="nav-close-img" @click="toCloseTryModel">
        <div class="model-content">
          <div class="model-title">关注公众号，免费领取【专业版会员】</div>
          <img src="@/assets/nav-bar-wx.png" style="width: 160px;height: 160px">
          <div class="model-desc">每个用户只能领取一次</div>
        </div>
      </div>
    </a-modal>

    <a-modal
      class="activity-model-box"
      :footer="null"
      v-model="activityVisible"
      :closable="false"
      :width="597.83"
    >
      <div class="activity-model">

        <div class="model-content-activity">
          <div class="activity-title" style="width: 158px">
            <div class="act-left">活动一</div>
            <div class="act-right">免费领取会员</div>
          </div>
          <div class="activity-desc"><span style="font-size: 8px;padding-right: 2px">◆</span>普通会员点击顶部<span style="font-weight: bold">【免费试用】</span>指引，关注平台公众号，<span style="font-weight: bold">免费领取</span>专业版会员</div>
          <div class="activity-title" style="width: 200px">
            <div class="act-left">活动二</div>
            <div class="act-right">邀请购买，免费升级</div>
          </div>
          <div class="activity-desc"><span style="font-size: 8px;padding-right: 2px">◆</span>运营版会员邀请其他用户成功开通任意会员，<span style="font-weight: bold">免费升级</span>专业版会员</div>

          <div class="activity-desc"><span style="font-size: 8px;padding-right: 2px">◆</span>邀请用户需在支付界面，<span style="font-weight: bold">填写</span>邀请企业名称或账户ID</div>
          <div class="activity-title" style="width: 144px">
            <div class="act-left">活动三</div>
            <div class="act-right">成单送会员</div>
          </div>
          <div class="activity-desc"><span style="font-size: 8px;padding-right: 2px">◆</span>专业版年度会员每完成1单，<span style="font-weight: bold">赠送</span>1个月会员，最多赠送12个月</div>
          <div class="activity-desc"><span style="font-size: 8px;padding-right: 2px">◆</span>成单标准：<span style="font-weight: bold">激活</span>三家或以上供应商完成报价，完成评价</div>
          <div class="activity-title" style="width: 186px">
            <div class="act-left">活动四</div>
            <div class="act-right">赠送行业咨询服务</div>
          </div>
          <div class="activity-desc"><span style="font-size: 8px;padding-right: 2px">◆</span>购买或续费企业版会员，<span style="font-weight: bold">赠送</span>一次线下深度咨询和线上解决方案报告</div>
        </div>
        <img src="@/assets/nav-close.png" class="nav-close-img-act" @click="toCloseActModel">
      </div>
    </a-modal>
  </div>
</template>

<script>
import Menus from '@/components/tools/menus.vue'
import SendMethods from '@/mixins/sendMethods.js'
import { mapState, mapActions } from 'vuex'
import { getAction } from '@/api/manage.js'
import Notice from '@/mixins/notice'
import { getCurBusiness } from '@/util/utils'
import { BusinessRoleType } from '@/util/constant'

export default {
  name: 'workSpaceHeader',
  mixins: [SendMethods, Notice],
  components: {
    Menus
  },
  data() {
    return {
      collapsed: false,
      business: {},
      tryVisible: false,
      activityVisible: false
    }
  },
  created() {
    this.business = getCurBusiness()
  },
  computed: {
    BusinessRoleType() {
      return BusinessRoleType
    },
    ...mapState({
      messageList: (state) => state.messageList, // 消息列表
      messageCount: (state) => state.messageCount // 平台信息
    })
  },
  methods: {
    ...mapActions(['deleteMessageList']),
    handleJump(msgCategory) {
      this.$router.push({
        path: '/messageManagement/messageList',
        query: {
          msgCategory
        }
      })
    },
    handleTrialModel() {
      this.tryVisible = true
    },
    toCloseTryModel() {
      this.tryVisible = false
    },
    handleActModel() {
      this.activityVisible = true
    },
    toCloseActModel() {
      this.activityVisible = false
    },
    /* 折叠菜单 */
    handleChangeMenus() {
      this.collapsed = !this.collapsed
      this.$emit('changeMenus', this.collapsed)
    },
    handleSendRequirement() {
      const that = this
      let token = ''
      const authInfo = localStorage.getItem('authInfo')
      if (authInfo) {
        const {
          Authorization
        } = JSON.parse(authInfo)
        token = Authorization // 让每个请求携带自定义 token 请根据实际情况自行修改
      }
      if (!token) {
        return
      }
      getAction('/user/token/info', {
        token
      }).then(res => {
        const { success, data } = res
        if (success) {
          const { businessDto } = data
          if (!businessDto) {
            this.$confirm({
              title: '提示',
              content: h => <div>未完善用户信息, 请先完善信息</div>,
              okText: '去完善',
              onOk() {
                that.$router.push({
                  path: '/businessManagement/companyInfo'
                })
              },
              onCancel() {
                console.log('Cancel')
              },
              class: 'test'
            })
          } else {
            this.$router.push({
              path: '/PublishingRequirements',
              query: {
                formType: 'product'
                // packagingMaterial: this.info.packagingMaterial
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.workSpaceHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 24px 17px 32px;
  background-color: #fff;
  height: 64px;
  position: relative;

  // &::after {
  //   display: block;
  //   content: '';
  //   width: calc(100% - 72px);
  //   border-bottom: 1px solid #EFEFEF;
  //   position: absolute;
  //   bottom: 0;
  // }

  .left-icon {
    font-size: 15px;

    .anticon {
      cursor: pointer;
      color: #262626;
    }
  }

  .right-info {
    color: #262626;
    font-weight: 500;
    font-family: 'PingFang SC';
    display: flex;
    grid-gap: 32px 32px;
    align-items: center;

    .send-btn {
      background-color: #FFF2EA;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #FF6026;
      font-size: 12px;
      font-weight: 500;
      width: 80px;
      height: 30px;
      cursor: pointer;
    }

    .nav-link {
      // padding-right: 15px;
      // border-right: 1px solid #efefef;
      cursor: pointer;
      height: 16px;
      display: flex;
      align-items: center;
    }

    .item {
      display: flex;
      align-items: center;
      padding-left: 5px;
      cursor: pointer;

      .label {
        margin: 0 4px;
      }
    }
  }
}

span.link {
  color: #E3E3E3;
}

.split-line {
  width: 1px;
  height: 20px;
  background: #EFEFEF;
  margin: 0 21px;
}

.link-text {
  color: #131212;
  cursor: pointer;
  position: relative;
  line-height: 24px;
}

::v-deep .ant-badge-multiple-words {
  padding: 0 6px;
  right: -7px;
}

::v-deep .ant-badge-count {
  padding: 0 6px;
  right: -12px;
}

.backToHome {
  height: 100%;
}

.sendInfo {
  height: 100%;
}

.notice-areas {
  height: 100%;
}

.header-title-wx {
  /* 微信小程序扫码操作工作台 */

  width: 144px;
  height: 24px;

  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */
  text-align: center;

  color: #9C9A99;


  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;

}

.nav-bar-img {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}

.link-select {
  display: flex;
}

.link-text-tip {
  position: relative;
  display: flex;
  align-items: center;

  .link-triangle {
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 7px solid #FF6E3D; /* 设置右侧边框颜色为蓝色 */
    position: absolute;
    left: -3px;
    z-index: 1;
  }

  .link-rectangle {
    padding: 4px;
    background: linear-gradient(122.45deg, #FF8B61 0%, #FF6431 33.14%, #FF571A 83.48%);
    border-radius: 4px;
    /* 免费试用 */
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #FFFFFF;
    height: 22px;
    flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 2;

  }
}

::v-deep .ant-modal .ant-modal-content {

  border-radius: 8px;
}

::v-deep .ant-modal-body {
  padding: 0px !important;
}

.try-model {
  height: 443px;
  background: url('~@/assets/try-bg.png');
  background-size: contain;
}

.activity-model {
  height: 560px;
  background: url('~@/assets/act-bg.png');
  background-size: contain;
}

.model-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 184px;
  gap: 16px;
}

.model-title {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height, or 112% */
  text-align: center;
  color: #000000;
}

.model-desc {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  color: #9C9A99;
}


.nav-close-img {
  width: 48px;
  height: 48px;
  position: absolute;
  cursor: pointer;
  bottom: -108px;
  margin-left: 140px;
}
.nav-close-img-act{
  width: 48px;
  height: 48px;
  position: absolute;
  cursor: pointer;
  bottom: -108px;
  margin-left: 280px;
}

.activity-model-box {
  background: transparent;
}

::v-deep .ant-modal-content {
  background: transparent;
  box-shadow: unset;
}
.model-content-activity{
  gap: 8px;
  display: flex;
  flex-direction: column;
  padding-top: 176px;
  justify-content: center;
  padding-left: 48px;
.activity-title{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px 0px 0px;
  width: 200px;
  height: 32px;
  border: 1px solid #FF6026;
  border-radius: 4px;
  box-sizing: border-box;

}
  .act-left{
    white-space: nowrap;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    height: 32px;
    background: linear-gradient(180deg, #FF8254 0%, #FF6026 100%);
    border-radius: 4px 0px 0px 4px;
    width: 58px;
  }
  .act-right{
    color: #FF6026;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    /* 品牌主题色 */
    color: #FF6026;
    padding: 4px 8px;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .activity-desc{
    height: 24px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #5C250B;    display: flex;
    flex-direction: row;
    align-items: center;

  }
}
</style>
