<!--
 * @Description: 用户菜单
 * @Author: zhang zhen
 * @Date: 2023-02-10 11:35:56
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-19 18:59:32
 * @FilePath: /page-sass/src/components/tools/menus.vue
-->
<template>
  <div>
    <a-dropdown placement="bottomRight" @visibleChange="handleInitState">
      <div class="cover" :class="!showEntry && 'hiddenLeft'">
        <a-avatar class="avatar" :src="setUserInfo().avatar || '/avatar.png'" />
        <slot></slot>
      </div>
      <div class="user-dropdown-menu-wrapper" slot="overlay">
        <div class="menus-cover">
          <!-- 用户信息 -->
          <div class="userInfo">
            <img :src="setUserInfo().avatar || require('@/assets/newHomePage/bg-avatar.png')" alt="" class="avatar-big">
            <div class="info-right">
              <div style="display: flex;flex-direction: column;">
                <span class="username">{{ business.name }} </span>
                <span class="userid">账户ID：{{ business.id }}<img src="@/assets/userId-copy.png" class="userid-copy"
                                                                  @click="handleCopy"> </span>
              </div>
              <div class="authentic" v-if="business.status == 2">
                <span class="label">帐号认证不通过</span><a href="/businessManagement/companyInfo" class="action">立即认证
                ></a>
              </div>
              <div class="authentic" v-if="business.status == 0">
                <span class="label">帐号认证中</span>
              </div>
            </div>
          </div>
          <!--          <div class="quickly-entry">-->
          <!--            &lt;!&ndash; :class="!showEntry && 'm-t-20'" &ndash;&gt;-->
          <!--            <div class="tradeIdentity">-->
          <!--              <div class="tradeIdentity-content">-->
          <!--                <span class="role-info">{{ roleType == 'BUSINESS' ? '企业' : '个人'-->
          <!--                  }}·{{ business?.roleName }}</span>-->
          <!--                <span class="memo">-->
          <!--                  <span class="spc">查看更多详情</span>-->
          <!--                  <a href="/workSpace" class="quick_link">进入工作台 ></a>-->
          <!--                </span>-->
          <!--              </div>-->
          <!--              <img src="/litter-card.png" alt="" class="icon">-->
          <!--            </div>-->
          <!--          </div>-->
          <!--普通会员-->
          <div class="quickly-entry">
            <div class="tradeIdentity">
              <div class="tradeIdentity-content">
                <img src="/ordinary-icon-min.png" class="icon-min-img">
                <div class="role-info">
                  <div class="role-title">普通会员</div>
                  <div class="role-desc">暂无会员</div>
                </div>
              </div>
              <div class="role-btn">立即开通</div>
            </div>
          </div>
          <!--次卡-->
          <div class="quickly-entry-secondary" v-if="false">
            <div class="tradeIdentity">
              <div class="tradeIdentity-content">
                <img src="/secondary-icon-min.png" class="icon-min-img">
                <div class="role-info">
                  <div class="role-title">次卡</div>
                  <div class="role-desc">到期时间:2025-06-06</div>
                </div>
              </div>
              <div class="role-btn">去升级</div>
            </div>
          </div>
          <!--运营版·会员-->
          <div class="quickly-entry-operation" v-if="false">
            <div class="tradeIdentity">
              <div class="tradeIdentity-content">
                <img src="/operation-icon-min.png" class="icon-min-img">
                <div class="role-info">
                  <div class="role-title">运营版·会员</div>
                  <div class="role-desc">到期时间:2025-06-06</div>
                </div>
              </div>
              <div class="role-btn">去升级</div>
            </div>
          </div>
          <!--专业版·会员-->
          <div class="quickly-entry-major" v-if="false">
            <div class="tradeIdentity">
              <div class="tradeIdentity-content">
                <img src="/major-icon-min.png" class="icon-min-img">
                <div class="role-info">
                  <div class="role-title">专业版·会员</div>
                  <div class="role-desc">到期时间:2025-06-06</div>
                </div>
              </div>
              <div class="role-btn">去升级</div>
            </div>
          </div>

          <!--企业版·会员-->
          <div class="quickly-entry-enterprise" v-if="false">
            <div class="tradeIdentity">
              <div class="tradeIdentity-content">
                <img src="/enterprise-icon-min.png" class="icon-min-img">
                <div class="role-info">
                  <div class="role-title">企业版·会员</div>
                  <div class="role-desc">到期时间:2025-06-06</div>
                </div>
              </div>
              <div class="role-btn">去续费</div>
            </div>
          </div>
        </div>
        <!--        <div class="block"></div>-->


        <!--  -->
                <router-link tag="div" to="/completeInformation" class="action-item" v-if="business?.roleType === BusinessRoleType.PURCHASER">
                  <img src="/sent_new.png" alt="" class="icon">
                  <span>发布需求</span>
                </router-link>
                <router-link tag="div" to="/userInfo/basicUserInfo" class="action-item">
                  <img src="/account_new.png" alt="" class="icon">
                  <span>账号管理</span>
                </router-link>
<!--         菜单区域-->
                <router-link tag="div" to="/requirement/requirementList" class="action-item"
                             v-if="business?.roleType === BusinessRoleType.PURCHASER">
                  <img src="/requirement_new.png" alt="" class="icon">
                  <span>我的需求</span>
                </router-link>
<!--         orderManagement-->
                <router-link tag="div" to="/orderManagement" class="action-item">
                  <img src="/order_new.png" alt="" class="icon">
                  <span>我的订单</span>
                </router-link>
        <div class="action-item" @click="handleRedemptionModel">
          <img src="@/assets/redemption-icon.png" alt="" class="icon">
          <span>会员兑换码</span>
        </div>
        <div class="action-item" @click="handleInvited">
          <img src="@/assets/invited.png" alt="" class="icon">
          <span>邀请</span>
        </div>
        <router-link @mouseover.native="hover=true" @mouseleave.native="hover=false" tag="div"
                     class="action-item" style="position: static" to="">
          <img src="/switch_new.png" alt="" class="icon">
          <span>切换身份</span>
          <div v-show="hover" class="hover-box">
            <a-radio-group style="width: 100%" v-model="userRoleType">
              <div class="action-item action-card-part"
                   style="margin-bottom: 8px;width: 100%" v-for="item in roleList"
                   @click="onSelectRole(item.roleType	)">
                <div style="display: flex;align-items: center">
                  <img src="@/assets/designer.png" v-if="item.roleType==4" alt="" class="icon">
                  <img src="@/assets/supplier.png" v-if="item.roleType==0" alt="" class="icon">
                  <img src="@/assets/purchaser.png" v-if="item.roleType==2" alt="" class="icon">
                  <img src="@/assets/servicer.png" v-if="item.roleType==1" alt="" class="icon">
                  <div style="display: flex;flex-direction: column">
                    <span class="user-nick-name">{{ item.roleName }}</span>
                    <!--                    <span class="user-role">{{ item.roleName }}</span>-->
                  </div>
                </div>
                <a-radio name="roleType" :value="item.roleType"></a-radio>
              </div>
            </a-radio-group>
          </div>

        </router-link>

        <div class="block more-margin"></div>
        <div class="action-item" @click="handleLoginOut">
          <img src="/action_3_new.png" alt="" class="icon">
          <span>退出登录</span>
        </div>
      </div>
    </a-dropdown>
    <ChangePassword ref="ChangePassword" />
    <a-modal v-model="redemptionVisible" title="会员兑换码" @ok="handleOk">
      <a-input
        style="width: 100%; height: 38px"
        placeholder="请输入会员兑换码"
        v-model="form.redemption"
      ></a-input>
      <template #footer>
        <div style="padding: 10px 16px;">
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">确认</a-button>
        </div>
      </template>

    </a-modal>
    <a-modal v-model="invitedVisible" title="邀请码" @ok="handleOk" class="invited-model">
      <div class="invited-container-base">
        <div class="base-code">BBQ123321</div>
        <div class="base-copy"><img src="/copy-main.png" style="width: 12px;height: 12px;margin-right: 4px"> 复制邀请码
        </div>
      </div>
      <div class="invited-container-input">
        <a-input
          style="width: 320px; height: 38px"
          v-model="form.invited"
        ></a-input>
        <div class="base-copy"><img src="/copy-main.png" style="width: 12px;height: 12px;margin-right: 4px"> 复制邀请码
        </div>
      </div>
      <template #footer>
        <div class="invited-footer">
          <img src="/xcx-code-invite.png" style="width: 120px;height: 120px;margin-right: 16px">
          <div><span style="color: #9C9A99">打开</span> <span class="invite-name">岭湃客小程序</span><span style="color: #9C9A99">「 微信扫码登录 」</span></div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ChangePassword from './changePassword.vue'
import { getAction, postAction } from '@/api/manage.js'
import sendMethods from '@/mixins/sendMethods.js'
import { getCurBusiness, getCurBusinessId, refreshUserTokenInfo, setCurBusiness, setCurBusinessId } from '@/util/utils'
import { BusinessRoleType } from '@/util/constant'

export default {
  name: 'userMenus',
  computed: {
    BusinessRoleType() {
      return BusinessRoleType
    }
  },
  mixins: [sendMethods],
  components: {
    ChangePassword
  },
  props: {
    showEntry: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      form: {},
      userInfo: {},
      roleType: 'BUSINESS',
      tradeIdentity: '0',
      Authentication: false,
      hover: false,
      value: '',
      roleList: [],
      userRoleType: '1',
      business: {},
      redemptionVisible: false,
      invitedVisible: false

    }
  },
  created() {
    const { tradeIdentity, roleType } = this.setUserInfo()
    this.roleType = roleType
    this.tradeIdentity = tradeIdentity
    this.userRoleType = getCurBusiness()?.roleType
  },
  methods: {
    ...mapActions(['loginOut']),
    ...mapGetters(['setUserInfo']),
    handleInitState(e) {
      if (!e) return
      refreshUserTokenInfo(this, () => {
        this.business = getCurBusiness()
        this.getBusinessRole(getCurBusinessId())
      })
    },
    getBusinessRole(businessId) {
      getAction('/v2/business/basic/info/queryByBusinessId', {
        businessId
      }).then(res => {
        const { success, data } = res
        if (success) {
          const { roleList, roleType } = data

          this.roleList = roleList
        }
      })
    },
    handleLoginOut() {
      const that = this
      this.$confirm({
        title: '提示',
        content: '真的要退出登录吗 ?',
        onOk() {
          that.loginOut().then(res => {
            that.$router.push({ path: '/' })
          })
        }
      })
    },
    onSelectRole(roleType) {
      postAction('/user/switchBusiness', {
        roleType
      }).then(res => {
        const { success, data } = res
        if (success) {
          refreshUserTokenInfo(this, () => {
            this.business = getCurBusiness()
            this.$message.success('切换身份成功')

            this.$router.push({
              path: '/workSpace'
            })
            window.location.reload()
          })
        }
      })
    }, handleCopy() {
      const _input = document.createElement('input') // 直接构建input
      _input.value = this.business.id // 设置内容
      document.body.appendChild(_input) // 添加临时实例
      _input.select() // 选择实例内容
      document.execCommand('Copy') // 执行复制
      document.body.removeChild(_input) // 删除临时实例
      this.$message.success('复制成功')
    }, handleRedemptionModel() {
      this.redemptionVisible = true
    },
    handleInvited() {
      this.invitedVisible = true
    },handleCancel(){
      this.redemptionVisible=false
    }
  }
}
</script>

<style lang="less" scoped>
.avatar {
  cursor: pointer;
  width: 36px;
  height: 36px;
  background-color: transparent;
  // margin: 0 30px;
  // margin-left: 10px;
}

.noLeft {
  .hiddenLeft {
    margin-left: 0 !important;
  }
}

.cover {
  display: flex;
  align-items: center;
  margin-left: 24px;

  &.hiddenLeft {
    margin-left: 12px;
  }
}

.user-dropdown-menu-wrapper {
  width: 280px;
  padding: 15px 0 6px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 6px;
  margin-top: 13px;
  box-shadow: 0px 4px 16px 0px #00000029;
  // box-shadow: 0px 0px 2px 0px #00000014;
  .userInfo {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .avatar-big {
    width: 32px;
    height: 32px;
    margin-right: 6px;
    border-radius: 50%;
  }

  .username {
    color: #262626;
    font-weight: 500;
    padding-bottom: 4px;
  }

  .quickly-entry, .quickly-entry-secondary, .quickly-entry-operation, .quickly-entry-major, .quickly-entry-enterprise {
    //background-color: #F4F5F7;
    position: relative;
    margin-bottom: 8px;
    padding: 12px;
    background: linear-gradient(273.3deg, #DDE4F3 0.71%, #E6EAF6 97.21%);
    border-radius: 6px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    .tradeIdentity {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      position: relative;
      font-size: 14px;
      font-weight: 600;
      justify-content: space-between;
    }

    .tradeIdentity-content {
      display: flex;
      flex-direction: row;
    }

    .role-info {
      padding-left: 8px;

      .role-title {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #30363F;
      }

      .role-desc {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #808080;

      }
    }

    .role-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 72px;
      height: 29px;
      background: linear-gradient(270deg, #2F2F2F 0%, #585857 100%);
      border-radius: 4px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 17px;
      display: flex;
      align-items: center;
      color: #F2D2A3;
    }
  }

  .quickly-entry-secondary {
    background: linear-gradient(151.72deg, #D7E2FF 15.52%, #E8EEFF 49.24%, #D7E2FF 84.33%);

    .role-info {
      .role-title {
        color: #264AFF;
      }

      .role-desc {
        color: rgba(38, 74, 255, 0.8);
      }

    }

    .role-btn {
      width: 60px;
    }
  }

  .quickly-entry-operation {
    background: linear-gradient(90deg, rgba(255, 241, 183, 0.7) 0%, rgba(255, 246, 200, 0.7) 46.5%, rgba(255, 244, 201, 0.7) 100%);

    .role-info {
      .role-title {
        color: #653C11;
      }

      .role-desc {
        color: #653C11CC;
      }
    }

    .role-btn {
      width: 60px;
    }
  }

  .quickly-entry-major {
    background: linear-gradient(90deg, #EFE6FD 0%, #F5F1FE 46.5%, #FAE8FB 100%);

    .role-info {
      .role-title {
        color: #641D80;
      }

      .role-desc {
        color: #641D80CC;
      }
    }

    .role-btn {
      width: 60px;
    }
  }

  .quickly-entry-enterprise {
    background: linear-gradient(90deg, #FFEDC6 0%, #FFF2D3 46.5%, #FFECC3 100%);

    .role-info {
      .role-title {
        color: #653C11;
      }

      .role-desc {
        color: #653C11;
      }
    }

    .role-btn {
      width: 60px;
    }
  }

  .menus-cover {
    padding: 0 15px;
    box-sizing: border-box;
  }

  .action-item {
    cursor: pointer;
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    color: #6D7075;
    font-size: 12px;
    font-weight: 500;

    .icon {
      //width: 24px;
      //height: 24px;
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }

    &:hover {
      background-color: #FFF2EA;

      //.role-items{
      //  display: none;
      //}
    }
  }

  .action-card-part {
    justify-content: space-between;
    padding: 8px 16px;
    height: 49px;
    border: 1px solid #EFEFEF;
    border-radius: 4px;
    align-items: center;
  }

  .user-nick-name {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #131212;
  }

  .user-role {

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #8F949D;
    padding-top: 2px;
  }

  .cut-role-action-item:hover {
    .role-items {
      display: block;
    }
  }
}

.m-t-20 {
  margin-top: 10px;
}

.block {
  background: #EFEFEF;
  width: calc(100% - 30px);
  height: 1px;
  margin: 0 auto 8px;
}

.authentic {
  display: flex;
  align-items: center;
  grid-gap: 0 8px;
  margin-top: 4px;

  .label {
    color: #8F949D;
    font-size: 12px;
  }

  .action {
    font-size: 12px;
  }
}

.more-margin {
  margin: 10px 0;
}

.hover-box {
  position: absolute;
  left: -200px;
  width: 200px;
  padding: 8px;
  background: #FFFFFF;
  /* 二级向下 shadow_md */
  //box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
}

.userid {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #9A9FA7;
  display: flex;
  align-items: center;
}

.userid-copy {
  width: 12px;
  height: 12px;
  margin-left: 8px;
}

.icon-min-img {
  width: 26px;
  height: 26px;
}

.invited-container-base {
  display: flex;
  gap: 16px;
  padding-bottom: 24px;

  .base-code {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #000000;
  }

  .base-copy {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */
    display: flex;
    align-items: center;
    color: #FF6026;
    cursor: pointer;
  }
}

.invited-container-input {
  display: flex;
  gap: 16px;
  align-items: center;

  .base-copy {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */
    display: flex;
    align-items: center;
    color: #FF6026;
  }
}

.invited-footer {
  width: 100%;
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 70px;
  height: 152px;
  background: #F4F5F7;
  border-top: 1px solid #EFEFEF;
  border-radius: 0px 0px 8px 8px;
  .invite-name{
    color: #131212;
    font-weight: bold;
  }
}

::v-deep  .ant-modal-footer {
  padding: 0px !important;
}
</style>
